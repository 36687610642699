import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import { Tabs } from 'antd';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const contentStyle = {
  height: '72px',
  lineHeight: '72px',
  flex: 1,
  margin: '0 4px',
  opacity: '1',
  borderRadius: '36px',
  fontFamily: 'PingFangSC-Medium, PingFang SC'
};

const tabBar = (props, defaultTabBar) => (
  <div className="about-content-tab-item">
    <defaultTabBar {...props} defaultActiveKey="0" />
  </div>
);

const About = () => {
  const { TabPane } = Tabs;
  const data = useStaticQuery(graphql`
    query AboutQuery {
      aboutJson {
        title
        subTitle
        bannerImg
        itemSlogan {
          key
          value
        }
        content {
          key
          id
        }
        aboutValue {
          title
          content
        }
        partners {
          name
          url
        }
        advantage {
          img
          title
          subtitle
        }
        values
      }
    }
  `);
  const { title, subTitle, bannerImg, itemSlogan, aboutValue, partners, advantage, values } = data.aboutJson;
  return (
    <Layout body-class="page-about">
      <SEO title="魔鬼鱼官网" />
      <Helmet>
        <meta
          name="团队介绍"
          content="魔鬼鱼团队介绍"
        />
      </Helmet>
      <div className="about_body">
        <div className="about_header">
          {/* <img src={bannerImg} alt="banner" className="about_banner" /> */}
          <picture>
            <source
              srcSet={`/images/2x/about/${bannerImg}@2x.png`}
              media="(min-resolution: 2dppx)"
            />
            <source
              srcSet={`/images/3x/about/${bannerImg}@3x.png`}
              media="(min-resolution: 3dppx)"
            />
            <img
              className="about_banner"
              src={`/images/1x/about/${bannerImg}@1x.png`}
              title="关于我们"
              alt="关于我们"
            />
          </picture>
          <div className="about_header_content">
            <div className="about_title">
              <source
                srcSet={`/images/2x/about/${title}@2x.png`}
                media="(min-resolution: 2dppx)"
              />
              <source
                srcSet={`/images/3x/about/${title}@3x.png`}
                media="(min-resolution: 3dppx)"
              />
              <img
                className="about_title_img"
                src={`/images/1x/about/${title}@1x.png`}
                title="mantas"
                alt="mantas"
              />
            </div>
            <div className="about_sub_title">{subTitle}</div>
            <div className="about_sub_title_divider" />
            {itemSlogan && itemSlogan.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>
                <div className="about_item_title">{item.key}</div>
                {item.value && item.value.map(value => (
                  <div className="about_item_content">{value}</div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="about-content">
          <div className="card-container">
            <Tabs
              type="card"
              className="card-bar"
              centered="true"
              tabPosition="top"
              animated="true"
              keyboard="true"
              defaultActiveKey="'3'"
              tabBarStyle={contentStyle}
            >
              <TabPane tab="关于魔鬼鱼" key="1">
                {aboutValue && aboutValue.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    <div className="about-content-tab-item-content-title">
                      {item.title}
                    </div>
                    <div className="about-content-tab-item-content-subtitle">
                      {item.content}
                    </div>
                  </React.Fragment>
                ))}
              </TabPane>
              <TabPane tab="战略合作伙伴" key="2">
                <div className="about-content-tab-item-partner-title">
                  战略合作伙伴
                </div>
                <div className="about-content-tab-item-partner-brand-container">
                  {partners && partners.map(item => (
                    <div className="about-content-tab-item-partner-brand">
                      <picture>
                        <source
                          srcSet={`/images/2x/about/${item.url}@2x.png`}
                          media="(min-resolution: 2dppx)"
                        />
                        <source
                          srcSet={`/images/3x/about/${item.url}@3x.png`}
                          media="(min-resolution: 3dppx)"
                        />
                        <img
                          className="about-content-tab-item-partner-brand-img"
                          src={`/images/1x/about/${item.url}@1x.png`}
                          title={item.name}
                          alt={item.name}
                        />
                      </picture>
                      {/* <img className="" src={item.url} alt={item.name} /> */}
                    </div>
                  ))}
                </div>
              </TabPane>
              <TabPane tab="特色优势" key="3">
                <div className="about-content-tab-item-adv-title-title">
                  特色优势
                </div>
                <div className="about-content-tab-item-adv">
                  {/* {advantage && advantage.map(item => (
                    <div className="about-content-tab-item-adv-item">
                      <div className="about-content-tab-item-adv-item-img-container">
                        <picture>
                          <source
                            srcSet={`/images/2x/about/${item.img}@2x.png`}
                            media="(min-resolution: 2dppx)"
                          />
                          <source
                            srcSet={`/images/3x/about/${item.img}@3x.png`}
                            media="(min-resolution: 3dppx)"
                          />
                          <img
                            className="about-content-tab-item-adv-item-img"
                            src={`/images/1x/about/${item.img}@1x.png`}
                            title={item.title}
                            alt={item.title}
                          />
                        </picture>
                      </div>
                      <div className="about-content-tab-item-adv-item-content">
                        <div className="about-content-tab-item-adv-title">
                          {item.title}
                        </div>
                        <div className="about-content-tab-item-adv-subtitle">
                          {item.subtitle}
                        </div>
                      </div>
                    </div>
                  ))} */}
                  <div className="about-content-tab-item-adv-item about-content-tab-item-adv-item-1">
                    <div className="about-content-tab-item-adv-item-img-container about-content-tab-item-adv-item-img-container-1">
                      <picture>
                        <source
                          srcSet={`/images/2x/about/${advantage[0].img}@2x.png`}
                          media="(min-resolution: 2dppx)"
                        />
                        <source
                          srcSet={`/images/3x/about/${advantage[0].img}@3x.png`}
                          media="(min-resolution: 3dppx)"
                        />
                        <img
                          className="about-content-tab-item-adv-item-img about-content-tab-item-adv-item-img-1"
                          src={`/images/1x/about/${advantage[0].img}@1x.png`}
                          title={advantage[0].title}
                          alt={advantage[0].title}
                        />
                      </picture>
                    </div>
                    <div className="about-content-tab-item-adv-item-content about-content-tab-item-adv-item-content-1">
                      <div className="about-content-tab-item-adv-title about-content-tab-item-adv-title-1">
                        {advantage[0].title}
                      </div>
                      <div className="about-content-tab-item-adv-subtitle about-content-tab-item-adv-subtitle-1">
                        {advantage[0].subtitle}
                      </div>
                    </div>
                  </div>
                  <div className="about-content-tab-item-adv-item about-content-tab-item-adv-item-2">
                    <div className="about-content-tab-item-adv-item-img-container about-content-tab-item-adv-item-img-container-2">
                      <picture>
                        <source
                          srcSet={`/images/2x/about/${advantage[1].img}@2x.png`}
                          media="(min-resolution: 2dppx)"
                        />
                        <source
                          srcSet={`/images/3x/about/${advantage[1].img}@3x.png`}
                          media="(min-resolution: 3dppx)"
                        />
                        <img
                          className="about-content-tab-item-adv-item-img about-content-tab-item-adv-item-img-2"
                          src={`/images/1x/about/${advantage[1].img}@1x.png`}
                          title={advantage[1].title}
                          alt={advantage[1].title}
                        />
                      </picture>
                    </div>
                    <div className="about-content-tab-item-adv-item-content about-content-tab-item-adv-item-content-2">
                      <div className="about-content-tab-item-adv-title about-content-tab-item-adv-title-2">
                        {advantage[1].title}
                      </div>
                      <div className="about-content-tab-item-adv-subtitle about-content-tab-item-adv-subtitle-2">
                        {advantage[1].subtitle}
                      </div>
                    </div>
                  </div>
                  <div className="about-content-tab-item-adv-item about-content-tab-item-adv-item-3">
                    <div className="about-content-tab-item-adv-item-img-container about-content-tab-item-adv-item-img-container-3">
                      <picture>
                        <source
                          srcSet={`/images/2x/about/${advantage[2].img}@2x.png`}
                          media="(min-resolution: 2dppx)"
                        />
                        <source
                          srcSet={`/images/3x/about/${advantage[2].img}@3x.png`}
                          media="(min-resolution: 3dppx)"
                        />
                        <img
                          className="about-content-tab-item-adv-item-img about-content-tab-item-adv-item-img-3"
                          src={`/images/1x/about/${advantage[2].img}@1x.png`}
                          title={advantage[2].title}
                          alt={advantage[2].title}
                        />
                      </picture>
                    </div>
                    <div className="about-content-tab-item-adv-item-content about-content-tab-item-adv-item-content-3">
                      <div className="about-content-tab-item-adv-title about-content-tab-item-adv-title-3">
                        {advantage[2].title}
                      </div>
                      <div className="about-content-tab-item-adv-subtitle about-content-tab-item-adv-subtitle-3">
                        {advantage[2].subtitle}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="企业价值观" key="4">
                <div className="about-content-tab-item-value-title">
                  企业价值观
                </div>
                <div className="about-content-tab-item-value-subtitle">
                  团队合作/客户第一/责任担当/诚信正直/拥抱变化
                </div>
                <div className="about-content-tab-item-value">
                  {values && values.map(item => (
                    <div className="about-content-tab-item-value-img-container">
                      {/* <img className="about-content-tab-item-value-img" src={item} alt="value" /> */}
                      <picture>
                        <source
                          srcSet={`/images/2x/about/${item}@2x.png`}
                          media="(min-resolution: 2dppx)"
                        />
                        <source
                          srcSet={`/images/3x/about/${item}@3x.png`}
                          media="(min-resolution: 3dppx)"
                        />
                        <img
                          className="about-content-tab-item-value-img"
                          src={`/images/1x/about/${item}@1x.png`}
                          title="企业价值观"
                          alt="企业价值观"
                        />
                      </picture>
                    </div>
                  ))}
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default About;
